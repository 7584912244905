import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { TailSpin } from 'react-loader-spinner';

import useAuth from '../hooks/useAuth';
import { getS3Name, handleLogout } from '../static/common';
import { Roles } from '../constants';
import { updateUserAgreement } from '../api/requests/user';
import useCustomToken from '../hooks/useCustomToken';
import CustomDataModal from './CustomDataModal';
import { useTranslation } from 'react-i18next';
import { generateKey } from '../utils/utils';

const { Paragraph, Text } = Typography;

const StyledViewer = styled.div`
  border: none;
  padding: 1rem;
  padding-right: 0rem;
`;

const StyledParagraph = styled(Paragraph)`
  overflow-y: auto;
  max-height: calc(100vh - 30rem);
  padding-right: 1rem;
`;

export const PdfViewer = () => {
  const { t } = useTranslation();
  const token = useCustomToken();
  const { user, userClient, clientId, changeClientId } = useAuth();
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);

  const getUserDetails = async () => {
    try {
      setIsLoader(true);
      if (user) {
        const selectedClient = user?.client?.find((item: any) => item?.id === clientId);
        const selectedRole = user?.role?.find((item: any) => item?.name === Roles.SITE_ADMIN);
        setIsModal(!selectedClient?.UserClient?.eula?.current?.accepted && (!selectedRole)
          && selectedClient?.UserClient?.eula?.current?.fileId);
        setIsLoader(false);
      }
    } catch (err: any) {
      console.log('err is', err);
    }
  };

  const submitHandler = async () => {
    setIsLoader(true);
    const details = await updateUserAgreement(user?.id ?? '');
    if (details?.data) {
      changeClientId(clientId);
    }
  };

  useEffect(() => {
    clientId && getUserDetails();
  }, [user, clientId]);

  return (
    <CustomDataModal
      title="Terms and Conditions"
      openModal={isModal}
      onCancel={handleLogout}
      footer={[
        <Button
          key={generateKey()}
          onClick={handleLogout}
        >
          {t('general.cancel')}
        </Button>,
        <Button
          key={generateKey()}
          onClick={submitHandler}
          type="primary"
          loading={isLoader}
        >
          I Agree
        </Button>
      ]}
    >
      {
        (isLoader) ? (
          <TailSpin
            color={token?.colorTextTertiary}
          />
        ) : (
          <StyledViewer>
            <StyledParagraph>
              {userClient?.UserClient?.eula?.current?.summary}
            </StyledParagraph>
            <Text>
              {'The license agreement can be accessed '}
            </Text>
            <a
              href={getS3Name(userClient?.UserClient?.eula?.current?.fileId)}
              download={userClient?.UserClient?.eula?.current?.fileId?.split('/')?.[1]}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </StyledViewer>
        )
      }
    </CustomDataModal>
  );
};
