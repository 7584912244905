import { type ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { hasAnyAuthority } from '../utils/hasAuthority';
import { Roles, Routes, Tokens } from '../constants';
import { PdfViewer } from '../components/PdfViewer';
import { ActivationExpired } from '../components/ActivationExpired';
import { ClientSelectionModal } from '../components/ClientSelectionModal';
import LoadingScreen from '../components/LoadingScreen';

interface RoleBasedGuardProps {
  children: ReactNode;
  RoutePermissions?: string[];
  RoleAccess?: string[];
  clientAccess?: boolean
}

const RoleBasedGuard = (props: RoleBasedGuardProps) => {
  const { children, RoutePermissions, RoleAccess, clientAccess } = props;
  const { user, userRole, permissions, userClient, clientId } = useAuth();

  if (clientAccess && !clientId) {
    return <Navigate to={Routes.login} />;
  }

  if (RoleAccess && !hasAnyAuthority([userRole], RoleAccess)) {
    return <Navigate to={Routes.login} />;
  }

  if (RoutePermissions && !hasAnyAuthority(permissions, RoutePermissions) && ![Roles.SITE_ADMIN, Roles.SITE_ADMIN_R]?.includes(userRole as any)) {
    return <Navigate to={Routes.login} />;
  }

  if (!localStorage.getItem(Tokens.accessToken)) {
    return <Navigate to={Routes.login} />;
  }

  return (
    <>
      {
        (userClient && !userClient?.active) ? <ActivationExpired /> : <PdfViewer />
      }
      {
        user && <ClientSelectionModal />
      }
      {
        ((clientId || [Roles.SITE_ADMIN, Roles.SITE_ADMIN_R]?.includes(userRole as any)) && user) ? (
          children
        ) : (
          <LoadingScreen />
        )
      }
    </>
  );
};

export default RoleBasedGuard;
