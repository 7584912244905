import { client } from '../client';
import { getData } from '../apiUtils';
import { Endpoints } from '../endpoints';

export const authenticateUser = async () => client.post(Endpoints.authenticate, {}).then(getData);

export const getClientBoundaryDetails = async () => client.get(`${Endpoints.clients}/boundary`).then(getData);

export const getClientBoundaryShapeDetails = async () => client.get(`${Endpoints.clients}/shape`).then(getData);

export const getStateShapeDetails = async () => client.get(`${Endpoints.clients}/stateShape`).then(getData);
