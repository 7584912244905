import React from 'react';
import { Skeleton } from 'antd';
import styled from 'styled-components';

const { Input } = Skeleton;

const InputWrapper = styled.div<{ $marginBottom: string | undefined }>`
  width: 100%;
  && .ant-skeleton.ant-skeleton-element {
    width: 100%;
    position: relative;
    top: 0.2rem;
    margin-bottom: ${(props) => props.$marginBottom || '0'};
  }
  && .ant-skeleton.ant-skeleton-element .ant-skeleton-input{
    min-width: 0;
  }
`;

interface CustomSkeletonProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  marginBottom?: string;
  active?: boolean;
}

const StyledInput = styled(Input) <CustomSkeletonProps>`
  && .ant-skeleton-input {
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '1rem'};
    border-radius: ${(props) => props.borderRadius};
}
`;

const CustomSkeleton: React.FC<CustomSkeletonProps> = ({
  width,
  height,
  borderRadius = '0.4rem',
  marginBottom,
  active = true,
}) => (
  <InputWrapper $marginBottom={marginBottom}>
    <StyledInput
      active={active}
      width={width}
      height={height}
      marginBottom={marginBottom}
      borderRadius={borderRadius}
    />
  </InputWrapper>
);

export default CustomSkeleton;
