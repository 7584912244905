import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import useCustomToken from '../hooks/useCustomToken';
import { handleLogout } from '../static/common';
import useAuth from '../hooks/useAuth';
import { WarningIcon } from '../icons/WarningIcon';
import CustomDataModal from './CustomDataModal';
import { generateKey } from '../utils/utils';

const { Paragraph, Link } = Typography;

const Box = styled.div`
  padding: 0.5rem 1rem 0rem 1rem;
`;

const WarningBox = styled.div<{ $token: any }>`
  background-color: ${(props) => props.$token?.colorTextQuaternary};
  border-radius: ${(props) => `${props.$token?.borderRadiusSM}px`};
  padding: 1.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
  && .ant-typography {
    font-size: ${(props) => `${props.$token?.fontSizeXL}px`};
    color: ${(props) => props.$token?.whiteBgColor};
    margin-bottom: 0;
  }
  && svg {
    height: 5rem;
    width: 5rem;
  }
`;

const StyledLink = styled(Link) <{ $token: any }>`
  border: ${(props) => `1px solid ${props.$token?.colorBorder}`};
  margin-left: 0.5rem;
  padding: 0.245rem 0.5rem;
  border-radius: ${(props) => `${props.$token?.borderRadiusSM}px`};
  color: ${(props) => props.$token?.whiteBgColor} !important;
  background-color: ${(props) => props.$token?.colorPrimary};

  &:hover {
    background-color: ${(props) => props.$token?.colorPrimaryHover};
  }
`;

const StyledButton = styled(Button)`
  padding: 0.25rem 0.75rem;
  height: 100%;
`;

export const ActivationExpired = () => {
  const { t } = useTranslation();
  const { user, userClient } = useAuth();
  const token = useCustomToken();

  return (
    <CustomDataModal
      title={t('activationModal.renewSubscription')}
      openModal={(user?.client?.length > 0) && !userClient?.active}
      onCancel={handleLogout}
      footer={[
        <StyledButton
          onClick={handleLogout}
          key={generateKey()}
        >
          {t('general.close')}
        </StyledButton>,
        <StyledLink
          $token={token}
          href="mailto:support@locusdata.io"
          target="_blank"
          key={generateKey()}
        >
          {t('activationModal.contactSupport')}
        </StyledLink>
      ]}
    >
      <Box>
        <WarningBox $token={token}>
          <WarningIcon fill={token?.whiteBgColor} />
          <Paragraph>{t('activationModal.licenseExpired')}</Paragraph>
        </WarningBox>
        <Paragraph>
          {t('activationModal.contactAdministrator')}
        </Paragraph>
      </Box>
    </CustomDataModal>
  );
};
