import React from 'react';
import { Modal, Typography } from 'antd';
import styled from 'styled-components';
import useCustomToken from '../hooks/useCustomToken';

const { Paragraph } = Typography;

interface CreateNewEquityModalProps {
  children: React.ReactNode;
  openModal: boolean;
  onCancel?: () => void;
  title?: string;
  footer?: any;
  width?: number | string
  props?: any
  titleSuffix?: any
  noPadding?: boolean
}

const StyledModal = styled(Modal) <{ $token: any, $noPadding: boolean }>`
  && .ant-modal-content {
    border: 1px solid ${(props) => props.$token?.colorTextQuaternary};
    padding: 0;
    border-radius: 6px;
    padding-bottom: ${(props) => !props.$noPadding && '1rem'};
  }
  && .ant-modal-footer {
    justify-content: center;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  & .container {
    padding: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 6rem);
  }
  && .ant-modal-footer button {
    box-shadow: none;
  }
  && div[data-testid="tail-spin-loading"] {
    justify-content: center;
  }
`;

const HeaderParagraph = styled(Paragraph) <{ $token: any }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: ${(props) => props.$token?.colorText};
  color: ${(props) => props.$token?.colorBgBase};
  padding: 0.5rem 1rem;
  border-top-left-radius: ${(props) => `${props.$token?.borderRadiusSM}px`};
  border-top-right-radius: ${(props) => `${props.$token?.borderRadiusSM}px`};
  margin-bottom: 0 !important;
`;

const CustomDataModal: React.FC<CreateNewEquityModalProps> = ({
  onCancel = () => { },
  openModal = false,
  footer = false,
  width = 600,
  title = '',
  children,
  props,
  titleSuffix,
  noPadding = false
}) => {
  const token = useCustomToken();

  return (
    <StyledModal
      $token={token}
      $noPadding={noPadding}
      title={false}
      centered
      open={openModal}
      closeIcon={false}
      footer={footer}
      width={width}
      onCancel={onCancel}
      maskClosable={false}
      {...props}
    >
      {
        title && (
          <HeaderParagraph $token={token}>
            {title}
            {titleSuffix}
          </HeaderParagraph>
        )
      }
      {children}
    </StyledModal>
  );
};

export default CustomDataModal;
