import { client } from '../client';
import { getData } from '../apiUtils';
import { Endpoints } from '../endpoints';

export const getAllClients = async () => (
  client.get(Endpoints.clients).then(getData)
);

export const createClient = async (data: any) => {
  const {
    name,
    logo,
    primaryColor,
    products,
    stateGeoIds,
    countyGeoIds,
    licenseEndDate,
    licenseStartDate,
    domainName,
    domainCheck,
    configurations,
    clientBoundaryName,
    agreementFileId,
    eulaSummary,
    isTrial,
    features
  } = data;
  const formData = new FormData();
  formData.append('file', agreementFileId);
  formData.append('name', name);
  formData.append('logo', logo);
  formData.append('primaryColor', primaryColor);
  formData.append('products', JSON.stringify(products));
  stateGeoIds && formData.append('stateGeoIds', JSON.stringify(stateGeoIds));
  countyGeoIds && formData.append('countyGeoIds', JSON.stringify(countyGeoIds));
  formData.append('licenseEndDate', licenseEndDate);
  formData.append('licenseStartDate', licenseStartDate);
  formData.append('domainName', domainName?.join(','));
  formData.append('domainCheck', domainCheck);
  formData.append('configurations', JSON.stringify(configurations));
  formData.append('clientBoundaryName', clientBoundaryName);
  formData.append('eulaSummary', eulaSummary);
  formData.append('isTrial', isTrial);
  formData.append('features', JSON.stringify(features));
  return client.post(Endpoints.clients, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(getData);
};

export const getClientById = async (id: string) => (
  client.get(`${Endpoints.clients}/${id}`).then(getData)
);

export const getClientBySlug = async (slug: string) => (
  client.get(`${Endpoints.clients}/slug/${slug}`).then(getData)
);

export const editClient = async (id: string, data: any) => (
  client.put(`${Endpoints.clients}/${id}`, data).then(getData)
);

export const deleteClient = async (id: string) => (
  client.delete(`${Endpoints.clients}/${id}`).then(getData)
);

export const editClientAgreement = async (id: string, data: any) => {
  const {
    file,
    eulaSummary,
    fileId
  } = data;
  const formData = new FormData();
  file && formData.append('file', file);
  fileId && formData.append('fileId', fileId);
  formData.append('eulaSummary', eulaSummary);
  return client.put(`${Endpoints.clients}/${id}/updateagreement`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(getData);
};

export const getDatsetByClient = async (clientId: string) => (
  client.get(`${Endpoints.clients}/${clientId}/datasets`).then(getData)
);
