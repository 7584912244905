import React from 'react';
import { IconProps } from '../static/common';

export const WarningIcon: React.FC<IconProps> = ({
  fill = '#323234'
}) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7512 33.75C21.7512 34.3467 21.9882 34.919 22.4102 35.341C22.8322 35.7629 23.4044 36 24.0012 36C24.5979 36 25.1702 35.7629 25.5922 35.341C26.0141 34.919 26.2512 34.3467 26.2512 33.75C26.2512 33.1533 26.0141 32.581 25.5922 32.159C25.1702 31.7371 24.5979 31.5 24.0012 31.5C23.4044 31.5 22.8322 31.7371 22.4102 32.159C21.9882 32.581 21.7512 33.1533 21.7512 33.75ZM22.5012 19.5V28.125C22.5012 28.3312 22.6699 28.5 22.8762 28.5H25.1262C25.3324 28.5 25.5012 28.3312 25.5012 28.125V19.5C25.5012 19.2937 25.3324 19.125 25.1262 19.125H22.8762C22.6699 19.125 22.5012 19.2937 22.5012 19.5ZM44.7996 40.125L25.2996 6.375C25.009 5.87344 24.5074 5.625 24.0012 5.625C23.4949 5.625 22.9887 5.87344 22.7027 6.375L3.20275 40.125C2.62619 41.1281 3.34806 42.375 4.50119 42.375H43.5012C44.6543 42.375 45.3762 41.1281 44.7996 40.125ZM8.07306 38.8172L24.0012 11.2453L39.9293 38.8172H8.07306Z"
      fill={fill}
    />
  </svg>
);
