import { TabsProps } from 'antd';
import i18next from 'i18next';

export const Tokens = {
  accessToken: 'access-token',
  refreshToken: 'refresh-token'
};

export const StateCountyListLimit = 3;

export const Routes = {
  login: '/login',
  home: '/home',
  studyArea: '/studyArea',
  studyAreaCreation: '/studyArea/create',
  projects: '/projects',
  collections: '/collections',
  timeofDay: '/timeofDay',
  mapLayers: '/mapLayers',
  mapLayerCreation: '/mapLayers/create',
  zoneSystem: '/zone-system',
  zoneSystemCreation: '/zone-system/create',
  equityCreation: '/equity/create',
  userGuides: '/userGuides',
  equity: '/equity',
  useCases: '/useCases',
  faqs: '/faqs',
  contactSupport: '/contactSupport',
  theme: '/theme',
  features: '/features',
  projectCreation: '/projectCreation',
  addDashboard: '/addDashboard',
  users: '/users',
  organization: '/organization',
  stats: '/stats',
  profile: '/myProfile',
  guide: '/guide',
  whatsNew: '/whatsNew',
  whatsNewRelease: '/whatsNewRelease',
  clients: '/clients',
  clientCreation: '/clients/create',
  createUser: '/create-user',
  dashboard: '/dashboard',
  passenger: '/passenger',
  transit: '/transit',
  truck: '/truck',
  admin: '/admin',
  data: '/data',
  person: '/person',
  signUp: '/signUp',
  dataRequest: '/dataRequests',
  personPlus: '/person+',
  signUpRequests: '/signUpRequests',
  resources: '/resources'
};

export const transparencyOptions = [
  { value: 0.1, label: '10%' },
  { value: 0.2, label: '20%' },
  { value: 0.3, label: '30%' },
  { value: 0.4, label: '40%' },
  { value: 0.5, label: '50%' },
  { value: 0.6, label: '60%' },
  { value: 0.7, label: '70%' },
  { value: 0.8, label: '80%' },
  { value: 0.9, label: '90%' },
  { value: 1, label: '100%' },
  { value: 0.2, label: '20%' }
];

export const pointSizeOptions = [
  { value: '2px', label: '2px' },
  { value: '4px', label: '4px' },
  { value: '6px', label: '6px' },
  { value: '8px', label: '8px' },
  { value: '10px', label: '10px' },
];

export const mapLayerShapes = {
  point: 'circle',
  line: 'line',
  polygon: 'fill'
};

export const mapLayerShapeOptions = [
  { value: 'circle', label: 'Point' },
  { value: 'line', label: 'Line' },
  { value: 'fill', label: 'Polygon' }
];

export const projectOptions: TabsProps['items'] = [
  {
    key: 'All',
    label: `${i18next.t('projects.allProjects')}`,
  },
  {
    key: 'Owned',
    label: `${i18next.t('projects.ownedProjects')}`,
  },
  {
    key: 'Shared',
    label: `${i18next.t('projects.sharedProjects')}`,
  },
];

export const enum Roles {
  SITE_ADMIN = 'SITE_ADMIN',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  USER = 'USER',
  SITE_ADMIN_R = 'SITE_ADMIN(R)'
}

export const enum LocusProducts {
  Passenger = 'Passenger',
  Charge = 'Charge',
  Perform = 'Perform',
  Safe = 'Safe',
  Truck = 'Truck',
  Transit = 'Transit',
  Person = 'Person',
  PersonPlus = 'Person+'
}

export const enum UserActivityTypes {
  Login = 'Login',
  Logout = 'Logout'
}

export const StorageKeys = {
  loggedIn: 'logged-in',
  redirect: 'redirect'
};

export const tableTabs = {
  owned: 'Owned',
  all: 'All',
  shared: 'Shared'
};

export const zipFileTypes: any = [
  'application/zip',
  'multipart/x-zip',
  'application/zip-compressed',
  'application/x-zip-compressed',
  'application/x-zip',
  'application/octet-stream'
];

export const csvFileTypes: any = [
  'text/csv',
  'application/csv',
  'application/octet-stream',
  'application/vnd.ms-excel'
];

export const truckTOD = '07e5d15a-079a-4481-b1cc-42ea77d515b8';

export const enum Permissions {
  ADD_USER = 'ADD_USER',
  UPDATE_USER = 'UPDATE_USER',
  GET_USER = 'GET_USER',
  DELETE_USER = 'DELETE_USER',
  ADD_SITE_ADMIN = 'ADD_SITE_ADMIN',
  UPDATE_SITE_ADMIN = 'UPDATE_SITE_ADMIN',
  GET_SITE_ADMIN = 'GET_SITE_ADMIN',
  DELETE_SITE_ADMIN = 'DELETE_SITE_ADMIN',
  ADD_CLIENT_ADMIN = 'ADD_CLIENT_ADMIN',
  UPDATE_CLIENT_ADMIN = 'UPDATE_CLIENT_ADMIN',
  GET_CLIENT_ADMIN = 'GET_CLIENT_ADMIN',
  DELETE_CLIENT_ADMIN = 'DELETE_CLIENT_ADMIN'
}

export const messages = {
  tokenNotActive: 'Token is not active',
  refreshTokenExpired: 'Refresh token expired',
  sessionNotActive: 'Session not active',
  sessionExpired: 'Session expired',
  usernotFound: 'User not found',
  invalidRefreshToken: 'Invalid refresh token'
};

export const displayTypes = {
  list: 'List',
  card: 'Card'
};

export const Urls = {
  stamenDevUrl: 'https://studio.stamen.com/camsys-map-prototype/show/latest/#lng=-82.69&lat=39.99&zoom=6.5&dashboard=e73a817c-2288-4f1d-99fc-675cb5d47cc7',
  zesstaPassengerDevUrl: 'https://locus-passenger-dev.zessta.com/#lng=-82.69&lat=39.99&zoom=6.5&dashboard=e73a817c-2288-4f1d-99fc-675cb5d47cc7'
};

export const timeBounds = {
  startTime: 'start-time',
  endTime: 'end-time'
};

export const pageDescription = [
  i18next.t('projects.selectDataProduct'),
  i18next.t('projects.defineStudyArea'),
  i18next.t('projects.selectTimeOfDaySegmentation'),
  i18next.t('projects.selectAdditionalAttributes'),
];

export const productDescription: any = {
  passenger: i18next.t('projects.passengerDescription'),
  charge: i18next.t('projects.chargeDescription'),
  perform: i18next.t('projects.performDescription'),
  safe: i18next.t('projects.safeDescription'),
  truck: i18next.t('projects.truckDescription'),
  transit: i18next.t('projects.transitDescription'),
  person: i18next.t('projects.passengerDescription'),
  'person+': i18next.t('projects.personPlusDescription')
};

export const projectCreationStepsId = {
  zoneSystem: 'zoneSystemIds',
  dashboardEquityDefinition: 'dashboardEquityDefinitionId',
  mapLayerId: 'mapLayerId'
};

export const mapLayerOptions = [
  {
    name: 'Transit Stations',
    id: '1'
  },
  {
    name: 'Bus routes',
    id: '2'
  },
  {
    name: 'Colleges/Universities',
    id: '3'
  },
  {
    name: 'Distribution centers',
    id: '4'
  }
];

export const MAPBOX_STYLE_URLS = {
  light: 'mapbox://styles/locus-cs/cllpdr7xd002o01of6pqchr55/draft',
  dark: 'mapbox://styles/locus-cs/cllpdrrc0002n01rd88nz8tf5/draft',
  street: 'mapbox://styles/mapbox/streets-v12'
};

export const MAP_ACCESS_TOKEN = 'pk.eyJ1IjoibG9jdXMtY3MiLCJhIjoiY2xscDlrbmY3MDQzdTNmbzVsa2QwOXF1eSJ9.C0aPmSKbycVfCXQ3h6KcBg';

export const MAPBOX_TILESET_BLOCKGROUPS = {
  url: 'mapbox://locus-cs.6967y69s',
  layerName: 'usblockgroups',
};

export const defaultStudyAreaName = 'Untitled Study Area';

export const pointMapStyle: any = {
  'circle-color': '#532CDD',
  'circle-opacity': 1,
  'circle-radius': 3,
  'circle-stroke-color': '#532CDD'
};

export const lineMapStyle: any = {
  'line-color': '#AC29D1',
  'line-opacity': 1,
  'line-width': 2.5
};

export const polygonMapStyle: any = {
  'fill-color': '#DB47E6',
  'fill-opacity': 0.5,
  'fill-outline-color': '#000000'
};

export const accessPermissionsList = {
  share: 'share',
  delete: 'delete',
  favorite: 'favorite',
  properties: 'properties',
  addCollection: 'addCollection',
  edit: 'edit',
  changeRole: 'changeRole',
  addClient: 'addClient',
  remove: 'remove',
  changeName: 'changeName'
};

export const accessPermissions = {
  project: [accessPermissionsList.share, accessPermissionsList.favorite, accessPermissionsList.changeName, accessPermissionsList.delete, accessPermissionsList.properties],
  projectHome: [accessPermissionsList.share, accessPermissionsList.favorite, accessPermissionsList.delete, accessPermissionsList.properties],
  view: [accessPermissionsList.share, accessPermissionsList.favorite, accessPermissionsList.delete, accessPermissionsList.addCollection],
  viewHome: [accessPermissionsList.share, accessPermissionsList.favorite, accessPermissionsList.delete],
  zoneSystem: [accessPermissionsList.delete],
  timePeriod: [accessPermissionsList.share, accessPermissionsList.delete],
  mapLayer: [accessPermissionsList.edit, accessPermissionsList.delete],
  collectionView: [accessPermissionsList.share, accessPermissionsList.favorite, accessPermissionsList.remove],
  collection: [accessPermissionsList.share, accessPermissionsList.favorite, accessPermissionsList.delete, accessPermissionsList.edit],
};

export const publicStatus = {
  None: 'none',
  Organization: 'organization',
  Environment: 'environment'
};

export const emptyDataMessage = {
  loading: i18next.t('emptyMessages.loading'),
  timeOfDay: i18next.t('emptyMessages.emptyTimeOfDay'),
  user: i18next.t('emptyMessages.emptyUser'),
  project: i18next.t('emptyMessages.emptyProject'),
  view: i18next.t('emptyMessages.emptyView'),
  collection: i18next.t('emptyMessages.emptyCollection'),
  studyArea: i18next.t('emptyMessages.emptyStudyArea'),
  zoneSystem: i18next.t('emptyMessages.emptyZoneSystem'),
  equity: i18next.t('emptyMessages.emptyEquity'),
  client: i18next.t('emptyMessages.emptyClient'),
  mapLayer: i18next.t('emptyMessages.emptyMapLayer'),
  features: i18next.t('emptyMessages.emptyFeatures'),
  requests: i18next.t('emptyMessages.emptyRequests')
};

export const favouriteToastMessage = {
  added: i18next.t('general.addedFavorites'),
  remove: i18next.t('general.removedFavorites')
};

export const accessRoles = {
  owner: 'Owner',
  viewer: 'Viewer',
  editor: 'Editor'
};

export const dashboardPendingStatus = ['In Progress', 'InProgress', 'Optimizing'];

export const maxDashboardLimit = 1000;

export const sampleFileUrls = {
  equity: 'https://camsys-data-portal.s3.amazonaws.com/sampleFiles/sampleEquity.csv',
  truckEquity: 'https://camsys-data-portal.s3.amazonaws.com/sampleFiles/truckSampleEquity.csv',
  domainList: 'https://camsys-data-portal.s3.amazonaws.com/sampleFiles/domainListExample.csv'
};

export const equityGeoTypes = {
  blockGroup: 'blockgroup',
  censusTract: 'censustract'
};

export const featureFlagKeys = {
  enableODFlowsPassenger: 'passenger.odflows.enable',
  enablePDFPassenger: 'passenger.pdf.enable',
  enableUserActivity: 'user.activity.enable',
  enableStateCountyFilter: 'dev.statecountyfilter.enable',
  enableSelectionsSync: 'dev.selectionssync.enable',
  enableClickableMap: 'dev.clickablemap.enable',
  enableODFlowsTruck: 'dev.odflows-truck.enable',
  enableMultiSelectFilter: 'dev.multiFilter.enable',
  enableColorOpactiySelection: 'dev.colorOpacity.enable',
  enableGISBuffering: 'dev.gisBuffer.enable',
  enableHideSelections: 'dev.hideSelections.enable',
  enableAggregationType: 'dev.aggregationType.enable'
};

export const productTabKeys = {
  person: 'Person',
  transit: 'Transit',
  truck: 'Truck',
  charge: 'Charge',
  perform: 'Perform',
  safe: 'Safe',
  personPlus: 'Person+'
};

export const studyPeriodType = {
  yearly: 'yearly',
  quarterly: 'quarterly'
};

export const guideTypes = {
  dataPortal: 'dataportal',
  person: 'person',
  truck: 'truck',
  transit: 'transit'
};

export const projectCreationSteps = {
  products: 'products',
  studyArea: 'studyArea',
  dataset: 'dataset',
  transit: 'transit',
  tod: 'tod',
  attributes: 'attributes'
};

export const signUpRequestType = {
  userCreation: 'userCreation'
};

export const signUpRequestStatus = {
  pending: 'Pending',
  approved: 'Approved',
  reapproved: 'Reapproved',
  declined: 'Declined',
  rejected: 'Rejected'
};

export const fieldType = {
  input: 'input',
  textArea: 'textarea',
  date: 'date',
  select: 'select',
  phone: 'phone',
  dateRange: 'dateRange'
};

export const requestApprovalType = {
  manual: 'Manual',
  automated: 'Automated'
};

export const requestType = {
  mapData: 'Data Request',
  signUp: 'Sign Up'
};

export const productsAccessPermissions = {
  timePeriod: [LocusProducts.Person, LocusProducts.PersonPlus],
  equity: [LocusProducts.Person, LocusProducts.PersonPlus, LocusProducts.Truck],
};

export const quartelyDropdownLimitForYearly = 12;

export const yearsSelectionLimit = 3;

export const aggregationTypes = {
  yearly: 'yearly',
  quarterly: 'quarterly'
};
