import moment from 'moment';
import * as R from 'ramda';
import _, { isNaN } from 'lodash';
import { User } from '../types/user';
import { ProjectCreationType } from '../types/table';
import { publicStatus, Roles, tableTabs } from '../constants';
import { getPublicStatus, removeDuplicatesForView } from '../static/common';

export const getPathname = (path: string) => {
  let fetchedPath = '';
  if (path?.includes('project')) {
    fetchedPath = 'projects';
  } else {
    fetchedPath = path.substring(path.indexOf('/') + 1,
      path.lastIndexOf('/') === 0 ? path.length : path.lastIndexOf('/'));
  }
  return fetchedPath;
};

export const generateKey = () => Math.random().toString(16).slice(2);

export const formatDate = (date?: string | Date, short?: boolean) => (
  short ? moment(date).format('MMM DD, YYYY') : moment(date).format('MMMM DD, YYYY')
);

export const validDateFormat = (dateString: string) => {
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? 'No Activity' : date.toLocaleDateString();
};

export const updateClientUserData = (clientUsers: any, user: User) => {
  const updatedData = clientUsers?.map((item: ProjectCreationType) => ({
    ...item,
    entityRole: item?.id === user?.id ? 'Creator' : ''
  }));

  return updatedData ?? [];
};

export const sortTimePeriodByTableHeader = (data: any, userId: string, sort?: { name: any, order: any }) => {
  data?.sort((itemA: any, itemB: any) => {
    const timePartitionA = itemA?.timePartition;
    const timePartitionB = itemB?.timePartition;

    const dateA: any = new Date(timePartitionA?.[sort?.name]);
    const dateB: any = new Date(timePartitionB?.[sort?.name]);

    const hasPriorityIdA = timePartitionA?.favourites?.find((favourite: any) => favourite?.favouritedBy === userId);
    const hasPriorityIdB = timePartitionB?.favourites?.find((favourite: any) => favourite?.favouritedBy === userId);

    if (hasPriorityIdA && !hasPriorityIdB) {
      return -1;
    }

    if (!hasPriorityIdA && hasPriorityIdB) {
      return 1;
    }
    if (sort?.order === 'descend') {
      return dateB - dateA;
    }
    return dateA - dateB;
  });

  return data;
};

export const sortByTableHeader = (data: any, userId: string, sort?: { name: any, order: any }) => {
  data.sort((itemA: any, itemB: any) => {
    const dateA: any = new Date(itemA?.[sort?.name]);
    const dateB: any = new Date(itemB?.[sort?.name]);

    const hasPriorityIdA = itemA?.favourites?.find((favourite: any) => favourite?.favouritedBy === userId);
    const hasPriorityIdB = itemB?.favourites?.find((favourite: any) => favourite?.favouritedBy === userId);

    if (hasPriorityIdA && !hasPriorityIdB) {
      return -1;
    }

    if (!hasPriorityIdA && hasPriorityIdB) {
      return 1;
    }

    if (sort?.order === 'descend') {
      return dateB - dateA;
    }
    return dateA - dateB;
  });

  return data;
};

export const compareDates = (prevDate: string, nxtDate: string) => (
  moment(prevDate) > moment(nxtDate) ? 1 : -1
);

export const getProjectUsedInNames = (data: any) => {
  const displayCount: any = 10;
  const dataByCount: any = R.take(displayCount, data);
  let result: any = R.pipe(
    R.map(R.prop('name') as any),
    R.join(', ')
  )(dataByCount);
  if (data?.length > displayCount) {
    result += `, ..., ${data?.length - displayCount} more`;
  }
  return result;
};

export const getDataByType: any = (all: any, shared: any, owned: any, type: string) => {
  const datasources = {
    [tableTabs.all]: all,
    [tableTabs.owned]: owned,
    [tableTabs.shared]: shared
  };

  /* eslint no-underscore-dangle: 0 */
  const selectedData: any = datasources[type] ?? [];
  const count = Math.ceil((removeDuplicatesForView(selectedData)?.length || 0) / 10) * 10;

  return { selectedData, count };
};

export const updateOptions = (options: any) => options.filter((item: any) => item.isAvailable === true);

export const getDarkColor = () => {
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }
  return color;
};

export const removeEnvironmentValueFromClientName = (clientName: string) => {
  const valuesToBeRemoved = ['dev', 'development'];
  let replacedString = clientName;
  valuesToBeRemoved.forEach((value: string) => {
    if (clientName.includes(value)) replacedString = clientName.replace(`${value}_`, '');
  });
  return replacedString;
};

export const sortTableHeaderByEula = (dataList: any, tableSort: any) => {
  const data = [...dataList];
  (data?.length > 0) && data.sort((itemA: any, itemB: any) => {
    // Sorting the eula by time in descending order for multiple clients of itemA
    const maxValueA = _.sortBy(itemA?.client,
      [(arr: any) => new Date(arr?.UserClient?.eula?.current?.timeStamp)]);
    // Sorting the eula by time in descending order for multiple clients of itemB
    const maxValueB = _.sortBy(itemB?.client,
      [(arr: any) => new Date(arr?.UserClient?.eula?.current?.timeStamp)]);

    // Taking the eula which is accepted for itemA
    const acceptedValueA = maxValueA?.find((item) => item?.UserClient?.eula?.current?.accepted);
    // Taking the eula which is accepted for itemB
    const acceptedValueB = maxValueB?.find((item) => item?.UserClient?.eula?.current?.accepted);

    // Taking the eula by the sorting order for itemA
    const orderValueA = (tableSort?.name === 'eula'
      ? (tableSort?.order === 'descend' ? maxValueA[maxValueA?.length - 1] : maxValueA[0]) : acceptedValueA);
    // Taking the eula by the sorting order for itemB
    const orderValueB = (tableSort?.name === 'eula'
      ? (tableSort?.order === 'descend' ? maxValueB[maxValueB?.length - 1] : maxValueB[0]) : acceptedValueB);

    const dateA: any = ((tableSort?.name === 'client') && orderValueA?.UserClient?.eula?.current?.timeStamp)
      ? new Date(orderValueA?.UserClient?.eula?.current?.timeStamp) : new Date(itemA?.[tableSort?.name]);
    const dateB: any = ((tableSort?.name === 'client') && orderValueB?.UserClient?.eula?.current?.timeStamp)
      ? new Date(orderValueB?.UserClient?.eula?.current?.timeStamp) : new Date(itemB?.[tableSort?.name]);

    if (orderValueA?.UserClient?.eula?.current?.timeStamp && !orderValueB?.UserClient?.eula?.current?.timeStamp) {
      return -1;
    }

    if (!orderValueA?.UserClient?.eula?.current?.timeStamp && orderValueB?.UserClient?.eula?.current?.timeStamp) {
      return 1;
    }

    if (tableSort?.order === 'descend') {
      return dateB - dateA;
    }
    return dateA - dateB;
  });
  return data;
};

export const renderUsername = (user: User) => (user?.deletedAt === null ? `${user?.firstName} ${user?.lastName}` : `${user?.firstName} ${user?.lastName} (User deleted)`);

export const renderEmail = (user: User) => {
  const updatedEmail = user?.email.split('_');
  updatedEmail.pop();
  return user?.deletedAt === null ? user?.email : updatedEmail.join('_');
};

export const getStateCountiesList = (arr: any, isState?: boolean) => {
  if (arr?.counties?.length > 0) {
    const counties = arr?.counties;
    if (isState) {
      const stateList = counties?.map((item: any) => item?.state?.name);
      const filteredList = _.uniq(stateList);
      return filteredList;
    }
    const countiesList = counties?.map((item: any) => item?.name);
    const filteredList = _.uniq(countiesList);
    return filteredList;
  }
  return [];
};

export const getCurrentSelectedMapLayer = (updatedMapLayers: string[], existingMapLayers: string[]) => {
  const combinedMapLayerIds = [...updatedMapLayers, ...existingMapLayers];
  return combinedMapLayerIds.filter((item) => !(updatedMapLayers.includes(item) && existingMapLayers.includes(item)))[0];
};

export const isPublicDisabled = (userRole: string, disableSwitch: boolean, status: string, isDefault?: boolean, owner?: boolean) => {
  const isUserOrSwitchDisabled = userRole === Roles.USER || disableSwitch;
  const isClientAdminWithEnvPublicStatus = userRole === Roles.CLIENT_ADMIN && status === publicStatus.Environment;
  const isClientAdminWithDefaultOrg = (userRole === Roles.CLIENT_ADMIN) && (status === publicStatus.Organization) && isDefault;
  const siteAdminOrg = (userRole === Roles.CLIENT_ADMIN) && (status === publicStatus.Organization) && !owner;

  return isUserOrSwitchDisabled || isClientAdminWithEnvPublicStatus || isClientAdminWithDefaultOrg || siteAdminOrg;
};

export const updateToggleStatus = (data: any[], id: string, publicStatusValue: string) => {
  const updatedData = [...data];
  const details = updatedData?.map((item: any) => (
    (item?.timePartition?.id === id) ? {
      ...item,
      timePartition: {
        ...item?.timePartition,
        isPublic: publicStatusValue
      }
    } : (item?.id === id) ? {
      ...item,
      isPublic: publicStatusValue
    } : item
  ));
  return details;
};

export const updateStateById = (data: any[], id: string, key: string, value: any) => {
  const updatedData = [...data];
  const details = updatedData?.map((item: any) => (
    (item?.timePartition?.id === id) ? {
      ...item,
      timePartition: {
        ...item?.timePartition,
        [key]: value
      }
    } : (item?.id === id) ? {
      ...item,
      [key]: value
    } : item
  ));
  return details;
};

export const hasProductAuthority = (permissions: string[], hasAnyPermissions: string[], allowed?: boolean) => R.cond([
  [
    () => permissions && permissions.length !== 0,
    () => {
      if (hasAnyPermissions?.length === 0) {
        return allowed ?? false;
      }
      return hasAnyPermissions && R.not(R.isEmpty(R.intersection(hasAnyPermissions, permissions)));
    }
  ],
  [R.T, R.always(false)]
])();
