import { client } from '../client';
import { getData } from '../apiUtils';
import { Endpoints } from '../endpoints';

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  clientId?: string;
  roleId?: string;
  id?: string
};

export const createUser = async (user: User) => (
  client.post(Endpoints.users, {
    ...user,
  }).then(getData)
);

export const getClientUser = async (id: string) => client.get(`${Endpoints.users}/client/${id}`).then(getData);

export const getAllUsers = async () => client.get(Endpoints.users).then(getData);

export const getUserById = async (id: string) => client.get(`${Endpoints.users}/${id}`).then(getData);

export const editUser = async (user: User, id: string) => (
  client.put(`${Endpoints.users}/${id}`, {
    ...user,
  }).then(getData)
);

export const deleteUser = async (id: string) => client.delete(`${Endpoints.users}/${id}`).then(getData);

export const updateUserAgreement = async (id: string) => (
  client.put(`${Endpoints.users}/${id}/updateagreement`, {
    isEulaAccepted: true
  }).then(getData)
);

export const editUserRole = async (userDetails: any, id: string) => (
  client.put(`${Endpoints.users}/${id}/add-client`, {
    ...userDetails,
  }).then(getData)
);

export const updateUserActivityForLogin = async (type: string, clientId: string) => (
  client.put(`${Endpoints.users}/user-activity`, {
    timeStamp: new Date(),
    activityType: type
  }, {
    ...(clientId?.length > 0) && {
      headers: {
        'x-lcs-clientid': clientId
      }
    }
  }).then(getData)
);

export const updateUserActivity = async (type: string) => (
  client.put(`${Endpoints.users}/user-activity`, {
    timeStamp: new Date(),
    activityType: type
  }).then(getData)
);
