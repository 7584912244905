import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Image, Typography, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';

import useCustomToken from '../hooks/useCustomToken';
import useAuth from '../hooks/useAuth';
import { getS3Name, getRawClientName, handleRoleLabel } from '../static/common';
import { Roles } from '../constants';
import CustomDataModal from './CustomDataModal';
import { ColumnsType } from 'antd/es/table';
import { ClientSelectionType } from '../types/clientSelection';
import CustomSkeleton from './CustomSkeleton';

const { Title, Text } = Typography;

const StyledText = styled(Text) <{ $token: any }>`
  font-size: ${(props) => `${props.$token?.fontSizeSM}px`};
  text-transform: capitalize;
`;

const Box = styled.div<{ $token: any }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem 0rem;
  margin: 0rem 1rem;
  gap: 1rem;

  && .ant-table-wrapper {
  width: 100%;
  }

  && .ant-table-wrapper .ant-table-container .ant-table-thead .ant-table-cell {
    background: ${(props) => props.$token?.colorFill};
    padding: 5px 10px;
    text-align: center;
  }

  && .ant-table-wrapper .ant-table-tbody .ant-table-row {
    cursor: pointer;
  }

  && .ant-table-wrapper .ant-table-container .ant-table-tbody .ant-table-cell {
    padding: 5px 10px;
    text-align: center;
  }

  && .ant-table-body::-webkit-scrollbar {
    width: 4px;
  }
  && .ant-table-body::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.$token?.colorFill};
  }
`;

const ImageBox = styled.div<{ $token: any }>`
  padding-bottom: 0;
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  && .ant-typography {
    margin: 0;
  }
`;

const StyledTailSpin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClientSelectionModal = () => {
  const { t } = useTranslation();
  const token = useCustomToken();
  const { user, clientList, userRole, clientId, changeClientId, isDropdownClicked } = useAuth();
  const [clientOptions, setClientOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isClientLoading, setIsClientLoading] = useState<boolean>(false);
  const [isImagePreview, setIsImagePreview] = useState<boolean>(false);

  useEffect(() => {
    const data = (user?.client?.length > 0) ? user?.client : clientList;
    for (let i = 0; i < data?.length; i++) {
      data[i].sno = i + 1;
    }
    setClientOptions(data);
    setLoading(false);
  }, [user]);

  const columns: ColumnsType<ClientSelectionType> = [
    {
      title: <StyledText $token={token}>{t('client.sno')}</StyledText>,
      dataIndex: 'sno',
      key: 'sno',
      render: (text) => (
        loading ? (
          <CustomSkeleton />
        ) : text
      ),
      width: '15%'
    },
    {
      title: `${t('client.clientLogo')}`,
      key: 'logo',
      dataIndex: 'logo',
      render: (text, record) => (
        loading ? (
          <CustomSkeleton />
        ) : (
          <ImageBox $token={token}>
            {
              record?.logo ? (
                <Image
                  height="4rem"
                  src={getS3Name(record?.logo)}
                  preview={false}
                />
              ) : (
                <Title level={2}>{getRawClientName(record?.name)}</Title>
              )
            }
          </ImageBox>
        )
      )
    },
    {
      title: `${t('client.clientName')}`,
      key: 'name',
      dataIndex: 'name',
      render: (text) => (
        loading ? (
          <CustomSkeleton />
        ) : text
      )
    },
    {
      title: `${t('client.role')}`,
      key: 'role',
      dataIndex: 'role',
      render: (text, record) => (
        loading ? (
          <CustomSkeleton />
        ) : (
          <>
            {handleRoleLabel((user?.client?.length > 0) ? record?.role?.[0]?.name : user?.role?.[0]?.name)}
          </>
        )
      )
    }
  ];

  return (
    <CustomDataModal
      title={t('client.selectAccount')}
      openModal={!clientId && ![Roles.SITE_ADMIN, Roles.SITE_ADMIN_R]?.includes(userRole as any)}
      props={{
        maskStyle: { ...!isDropdownClicked && { backgroundColor: token?.colorBorder } }
      }}
      width="50%"
    >
      {
        isClientLoading ? (
          <StyledTailSpin>
            <TailSpin
              color={token?.colorTextTertiary}
            />
          </StyledTailSpin>
        ) : (
          <Box $token={token}>
            <Table
              dataSource={clientOptions}
              columns={columns}
              rowKey="id"
              scroll={{ y: 500 }}
              pagination={false}
              onRow={(record: any) => ({
                onClick: (ev: any) => {
                  ev?.preventDefault();
                  if (!isImagePreview) {
                    changeClientId(record?.id, false, user);
                    setIsClientLoading(true);
                  }
                  setIsImagePreview(false);
                },
              })}
            />
          </Box>
        )
      }
    </CustomDataModal>
  );
};
