import React, { ReactNode } from 'react';
import { Navigate } from 'react-router';
import { useParams, useLocation } from 'react-router-dom';
import { StorageKeys } from '../constants';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard = (props: GuestGuardProps) => {
  const { children } = props;
  const params = useParams();
  const location = useLocation();
  if (!localStorage.getItem('access-token')) {
    params?.viewId && localStorage.setItem(StorageKeys.redirect, location?.pathname);
    return <Navigate to="/" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default GuestGuard;
