import _ from 'lodash';
import i18next from 'i18next';

import { REACTS3URL } from '../config';
import { accessPermissionsList, accessRoles, LocusProducts, publicStatus, Roles, StorageKeys, Tokens } from '../constants';
import KeycloakServices from '../Keycloak-config';

export interface IconProps {
  fill?: string
}

export const locusTokenOverrides = {
  colorBgBase: '#FAFAFA',
  colorPrimary: '#0D5C80',
  colorTextBase: '#3A3748',
  colorSuccess: '#28B67C',
  colorWarning: '#FADB14',
  fontFamily: 'Inter',
  whiteColor: '#FFFFFF',
  colorError: '#EA3E3E',
  totalClients: '#1677FF',
  activeUsers: '#389E0D',
  inactiveUsers: '#D48806',
  pendingColor: '#FAAD14',
  pendingColorHover: '#FFF1B8'
  // using colorTextTertiary token rgba(58, 55, 72, 0.65) #3A374873 in place of #8A8A8E
  // using colorTextQuaternary token rgba(63, 77, 85, 0.25) #3F4D5540 in place of #DBDFE1
};

export const handleLogout = () => {
  KeycloakServices.logout();
  localStorage.removeItem(Tokens.accessToken);
  localStorage.removeItem(Tokens.refreshToken);
  localStorage.removeItem('x-lcs-clientid');
  localStorage.removeItem(StorageKeys.loggedIn);
  localStorage.removeItem(StorageKeys.redirect);
};

export const handleFavourite = (id: string, tableData: any) => {
  const data = [...tableData];
  const updatedData = data?.map((item: any) => ({
    ...item,
    isFavourite: item?.id === id ? !item?.isFavourite : item?.isFavourite
  }));
  return updatedData;
};

export const sortByFavorite = (data: any) => {
  const favouriteData = data?.filter((itm: any) => itm?.isFavourite);
  const remainingData = data?.filter((itm: any) => !itm?.isFavourite);
  return data?.length > 0 ? [...favouriteData, ...remainingData] : [];
};

export const optionArray = (arr: any[], key?: number | string) => (
  arr?.map((itm: any, ind: number) => ({ id: itm?.geoId, title: itm?.name, key: key ? key?.toString()?.concat(`-${ind.toString()}`) : ind.toString() }))
);

export const dropdownMenuOptions = (arr: any) => (
  arr.map((itm: any) => ({
    label: itm?.displayName ? itm?.displayName : itm?.name,
    value: itm?.id
  }))
);

export const userIdentifier = (arr: any, id: string) => (
  arr?.find((item: any) => item?.favouritedBy === id) ? true : false
);

export const sliceForPagination = (arr: any, page: number, size?: number) => (
  arr?.slice(((page - 1) * 10), ((page - 1) * 10) + (size ?? 10))
);

export const handleSearch = (arr: any, val: string) => (
  val?.trim()?.length > 0 ? arr?.filter((item: any) => {
    const name = item?.name ?? item?.firstName + item?.lastName;
    return name ? name?.toLowerCase()?.includes(val?.toLowerCase()) : true;
  }) : arr
);

export const handleTimeOfDaysSearch = (arr: any, val: string) => (
  val?.length > 0 ? arr?.filter((item: any) => item?.timePartition?.name?.toLowerCase()?.includes(val?.toLowerCase())) : arr
);

export const getStatusColor = (val: string) => (
  val === 'Active' ? 'success' : val === 'Paused' ? 'warning' : 'danger'
);

export const getRoleName = (val: string) => (
  val ? val?.toLowerCase()?.replaceAll('_', ' ') : '-'
);

export const getS3Name = (val: string) => REACTS3URL + val;

export const handleCountyValidation = (stateCounty?: any, handleValidation?: any) => (
  stateCounty?.countyGeoIds?.length === 0 ? (
    stateCounty?.stateGeoIds ? stateCounty?.stateGeoIds?.length === 0
      : handleValidation()
  ) : handleValidation()
);

export const getPublicStatus = (val: boolean, userRole: string, clientId?: string) => (
  val ? userRole === Roles.SITE_ADMIN && !clientId ? publicStatus.Environment
    : publicStatus.Organization : publicStatus.None
);

export const getPublicStatusForStudyArea = (val: boolean) => (
  val ? publicStatus.Organization : publicStatus.None
);

export const handleDropdownSearch = (ev: string, option: any) => (
  ev?.trim()?.length > 0 ? option?.label?.toLowerCase()?.includes(ev?.toLowerCase()) : true
);

export const getStudyAreaOptions = (activeList: string[], disableList?: string[]) => {
  const listCards = [
    {
      id: '1',
      name: i18next.t('studyArea.existingStudyArea'),
      desc: i18next.t('studyArea.existingStudyAreaDescription'),
      disabled: disableList?.includes('1'),
      isActive: activeList?.includes('1')
    },
    {
      id: '2',
      name: i18next.t('studyArea.selectedStatesCounties'),
      desc: i18next.t('studyArea.selectedStatesCountiesDescription'),
      disabled: disableList?.includes('2'),
      isActive: activeList?.includes('2')
    },
    {
      id: '3',
      name: i18next.t('studyArea.newStudyArea'),
      desc: i18next.t('studyArea.newStudyAreaDescription'),
      disabled: disableList?.includes('3'),
      isActive: activeList?.includes('3')
    },
  ];
  const updatedList = listCards?.filter((item: any) => item?.isActive);
  return updatedList;
};

export const getDatasetStudyAreaOptions = (activeList: string[], disableList?: string[]) => {
  const listCards = [
    {
      id: '1',
      name: i18next.t('studyArea.defaultStudyArea'),
      desc: i18next.t('studyArea.defaultStudyArea'),
      disabled: disableList?.includes('1'),
      isActive: activeList?.includes('1')
    },
    {
      id: '2',
      name: i18next.t('studyArea.selectedStatesCounties'),
      desc: i18next.t('studyArea.selectedStatesCountiesDescription'),
      disabled: disableList?.includes('2'),
      isActive: activeList?.includes('2')
    },
    {
      id: '3',
      name: i18next.t('studyArea.newStudyArea'),
      desc: i18next.t('studyArea.newStudyAreaDescription'),
      disabled: disableList?.includes('3'),
      isActive: activeList?.includes('3')
    },
  ];
  const updatedList = listCards?.filter((item: any) => item?.isActive);
  return updatedList;
};

export const getOwnedDetails = (arr: any, id: string) => (
  arr?.length ? arr?.filter((item: any) => (item?.ownerId === id) || (item?.timePartition?.ownerId === id)) : []
);

export const getViewCount = (arr: any) => {
  let count = 0;
  arr?.length > 0 ? arr?.map((item: any) => {
    count += item?.views?.length ?? 0;
    return item;
  }) : 0;
  return count;
};

export const removeDuplicates = (arr: any) => (
  arr?.length > 0 ? _.uniqWith(arr, _.isEqual) : arr
);

export const removeDuplicatesForView = (arr: any) => (
  arr?.length > 0 ? _.uniqBy(arr, 'id') : arr
);

export const splitByComma: any = (val: string) => val?.split(',');

export const handleRoleLabel = (val: any) => (
  val?.replaceAll('_', ' ')?.toLowerCase()?.replace(/(?:^|\s|["'([{])+\S/g, (match: any) => match.toUpperCase())
);

export const getRawClientName = (name: string) => {
  const clientPrefix = ['development', 'dev'];
  let clientName = name;
  clientPrefix?.forEach((prefix: string) => {
    clientName = clientName?.replace(`${prefix}_`, '');
  });
  return clientName;
};

export const getFilterOptions = (options: any) => {
  const optionsData = _.uniqWith(options, _.isEqual);
  return optionsData?.filter((obj: any) => !_.isNull(obj) && (obj !== 'undefined') && obj);
};

export const getStrCapitalize = (str: string) => (
  (str?.length > 0) ? str?.toLowerCase()?.replace(/\b\w/g, (char: string) => char?.toUpperCase()) : str
);

export const dropdownOptionsForStringArray = (arr: any) => (
  arr?.length > 0 ? arr?.map((item: any) => ({ label: getStrCapitalize(item), value: item })) : []
);

export const getRoleType = (val: string) => (
  (val?.length > 0) ? (val?.toLowerCase()?.includes('lite') ? 'Lite' : 'Regular') : val
);

export const getMapLayerType = (val: string) => {
  if (val?.toLowerCase() === 'circle') {
    return 'point';
  }
  if (val?.toLowerCase() === 'fill') {
    return 'polygon';
  }
  return val;
};

export const dropdownOptionsForFilters = (arr: any) => (
  arr?.length > 0 ? arr?.map((item: any) => ({ title: getStrCapitalize(getMapLayerType(item)), key: item })) : []
);

export const filterDataByOptions = (data: any, filter: any) => (
  data?.filter((item: any) => {
    const options = Object.keys(filter ?? {});
    let res = true;

    options?.forEach((obj: any) => {
      if ((filter?.[obj]?.length > 0) && res) {
        // For time period
        if (obj === 'timePeriod') {
          const list = (item?.dashboard?.length > 0)
            ? item?.dashboard?.map((itm: any) => itm?.timePartition?.name)
            : item?.dashboard?.timePartition?.name;
          res = _.intersection(filter?.[obj], (item?.dashboard?.length > 0) ? list : [item?.dashboard?.timePartition?.name])?.length > 0;
          // For projects
        } else if (obj === 'projects') {
          const list = (item?.dashboards?.length > 0)
            ? item?.dashboards?.map((itm: any) => itm?.name)
            : (item?.dashboard?.length > 0) ? item?.dashboard?.map((itm: any) => itm?.name) : item?.dashboard?.name;
          res = _.intersection(filter?.[obj], ((item?.dashboard?.length > 0) || (item?.dashboards?.length > 0)) ? list : [item?.dashboard?.name])?.length > 0;
          // For createdBy
        } else if (obj === 'createdBy') {
          const filterName = filter?.[obj]?.map((name: any) => name?.replace(' (User deleted)', ''));
          res = filterName?.includes(
            item?.[obj]
              ? `${item?.[obj]?.firstName} ${item?.[obj]?.lastName}`
              : `${item?.owner?.firstName} ${item?.owner?.lastName}`
          );
          // For owner
        } else if (obj === 'owner') {
          const filterName = filter?.[obj]?.map((name: any) => name?.replace(' (User deleted)', ''));
          res = filterName?.includes(
            item?.[obj]
              ? `${item?.[obj]?.firstName} ${item?.[obj]?.lastName}`
              : `${item?.owner?.firstName} ${item?.owner?.lastName}`
          );
          // For createdBy in time partition
        } else if (obj === 'createdByTimePartition') {
          res = filter?.[obj]?.includes(`${item?.timePartition?.createdBy?.firstName} ${item?.timePartition?.createdBy?.lastName}`);
          // For owner in time partition
        } else if (obj === 'ownerTimePartition') {
          res = filter?.[obj]?.includes(`${item?.timePartition?.owner?.firstName} ${item?.timePartition?.owner?.lastName}`);
          // For study area and product type
        } else if (['studyArea', 'product']?.includes(obj)) {
          res = filter?.[obj]?.includes(item?.[obj]?.name ?? item?.dashboard?.[obj]?.name ?? item?.dashboard?.[0]?.[obj]?.name
            ?? item?.dashboard?.[0]?.dataset?.name ?? item?.dashboard?.dataset?.name);
          // For status
        } else if (['status']?.includes(obj)) {
          res = filter?.[obj]?.includes(item?.dashboard?.[obj] ?? item?.[obj]);
          // For role type
        } else if (['roleType']?.includes(obj)) {
          const list = (item?.role?.length > 0) ? item?.role?.map((role: any) => getRoleType(
            getStrCapitalize(getRoleName(role?.name))
          )) : getRoleType(item?.role?.name);
          res = _.intersection(filter?.[obj], (item?.role?.length > 0) ? list : [getRoleType(item?.role?.name)])?.length > 0;
          // For role
        } else if (['role']?.includes(obj)) {
          const list = (item?.[obj]?.length > 0) ? item?.[obj]?.map((role: any) => getStrCapitalize(getRoleName(role?.name))) : item?.[obj]?.name;
          res = _.intersection(filter?.[obj], (item?.[obj]?.length > 0) ? list : [item?.[obj]?.name])?.length > 0;
          // For states
        } else if (['states']?.includes(obj)) {
          const list = (item?.studyArea?.counties?.length > 0) ? item?.studyArea?.counties?.map((county: any) => county?.state?.name) : item?.[obj]?.name;
          res = _.intersection(filter?.[obj], (item?.studyArea?.counties?.length > 0) ? list : [item?.[obj]?.name])?.length > 0;
          // For counties
        } else if (['counties']?.includes(obj)) {
          const list = (item?.studyArea?.counties?.length > 0) ? item?.studyArea?.counties?.map((county: any) => county?.name) : item?.[obj]?.name;
          res = _.intersection(filter?.[obj], (item?.studyArea?.counties?.length > 0) ? list : [item?.[obj]?.name])?.length > 0;
          // For state and county
        } else if (['stateCounty']?.includes(obj)) {
          const filterList = filter?.[obj]?.map((list: string) => (list?.split('-')?.length > 1 ? list?.split('-')?.[2] : list?.split('-')?.[0]));
          const stateList = (item?.studyArea?.counties?.length > 0) ? item?.studyArea?.counties?.map((county: any) => county?.state?.name) : item?.[obj]?.name;
          const countyList = (item?.studyArea?.counties?.length > 0) ? item?.studyArea?.counties?.map((county: any) => county?.name) : item?.[obj]?.name;
          res = _.intersection(filterList, (item?.studyArea?.counties?.length > 0) ? [...stateList, ...countyList] : [item?.[obj]?.name])?.length > 0;
        } else {
          res = filter?.[obj]?.includes(getMapLayerType(item?.[obj]?.name ?? item?.[obj]));
        }
      }
    });
    return res;
  })
);

export const getPublicAccess = (record: any) => (
  record?.dashboard?.project?.[0]?.isPublic ?? record?.isPublic ?? record?.timePartition?.isPublic
);

export const getProductName = (record: any) => (
  record?.dashboard?.product?.name ?? record?.dashboard?.[0]?.product?.name ?? record?.product?.name
);

export const getAccessDetails = (arr: any, userId: string) => ((arr?.length > 0) ? arr?.find((item: any) => item?.accessReceiverId === userId) : arr);

export const isDarkTheme = (hex: any) => {
  // Remove the hash at the start if it's there
  const code = hex?.replace(/^#/, '');

  const r = parseInt(code?.substring(0, 2), 16);
  const g = parseInt(code?.substring(2, 4), 16);
  const b = parseInt(code?.substring(4, 6), 16);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Determine if the color is light or dark

  return luminance > 50 ? false : true;
};

export const isLightTheme = (hex: any) => {
  // Remove the hash at the start if it's there
  const code = hex?.replace(/^#/, '');

  const r = parseInt(code?.substring(0, 2), 16);
  const g = parseInt(code?.substring(2, 4), 16);
  const b = parseInt(code?.substring(4, 6), 16);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Determine if the color is light or dark

  return luminance > 170 ? true : false;
};

export const searchValidation = (ev: string, val: string) => (
  val?.trim()?.length > 0 ? ev?.toLowerCase()?.includes(val?.toLowerCase()) : true
);

export const removeEditablePermission = (list: string[]) => (
  (list?.length > 0) ? list.filter((item: string) => ![accessPermissionsList.edit, accessPermissionsList.delete,
    accessPermissionsList.changeName, accessPermissionsList.remove, accessPermissionsList.share, accessPermissionsList.addCollection]?.includes(item)) : list
);

export const filterPermissions = (permissions: string[], userRole: any, record: any, userId: string, isReadOnly: boolean, considerDefault?: boolean) => {
  let list = permissions;
  const isConsiderDefault = considerDefault ?? true;
  if (record?.isDefault && isConsiderDefault) {
    list = list?.filter((item: any) => ![accessPermissionsList.remove, accessPermissionsList.delete]?.includes(item));
    if (userRole === Roles.CLIENT_ADMIN) {
      list = list?.filter((item: any) => ![accessPermissionsList.edit]?.includes(item));
    }
  }
  if ([publicStatus.Organization, publicStatus.Environment]?.includes(getPublicAccess(record))) {
    list = list?.filter((item: any) => ![accessPermissionsList.share]?.includes(item));
    if ([Roles.USER]?.includes(userRole)) {
      list = list?.filter((item: any) => ![accessPermissionsList.edit]?.includes(item));
    }
    if (!record?.isDefault && !record?.dashboard && !record?.timePeriods) {
      permissions?.includes(accessPermissionsList.share) && list.push(accessPermissionsList.share);
    }
    if ((record?.ownerId === userId) || ((record?.timePartition?.ownerId === userId)) || [Roles.SITE_ADMIN]?.includes(userRole)) {
      if (isReadOnly) {
        return removeEditablePermission(list);
      }
      return list;
    }
    if ([Roles.CLIENT_ADMIN, Roles.USER]?.includes(userRole)) {
      list = list?.filter((item: any) => ![accessPermissionsList.edit, accessPermissionsList.delete, accessPermissionsList.changeName]?.includes(item));
    }
    if ((![Roles.CLIENT_ADMIN, Roles.SITE_ADMIN]?.includes(userRole) || (getProductName(record) === LocusProducts.Transit)) && isConsiderDefault) {
      list = list?.filter((item: any) => ![accessPermissionsList.remove, accessPermissionsList.delete]?.includes(item));
    }
  }
  if (record?.ownerId === userId) {
    if (isReadOnly) {
      return removeEditablePermission(list);
    }
    return list;
  }
  if ((getAccessDetails(record?.entityaccesses, userId)?.accessRole?.name ?? record?.accessRole) === accessRoles.viewer) {
    list = list?.filter((item: any) => (
      ![accessPermissionsList.share, accessPermissionsList.delete,
        accessPermissionsList.remove, accessPermissionsList.edit, accessPermissionsList.changeName]?.includes(item)
    ));
  }
  if ((getAccessDetails(record?.entityaccesses, userId)?.accessRole?.name ?? record?.accessRole) === accessRoles.editor) {
    list = list?.filter((item: any) => (
      ![accessPermissionsList.delete, accessPermissionsList.remove, accessPermissionsList.changeName]?.includes(item)
    ));
  }
  if (isReadOnly) {
    return removeEditablePermission(list);
  }
  return list;
};

export const uniqBySort = (arr: any) => (
  (arr?.length > 0) ? _.uniqBy(arr, 'geoId')?.sort((prevOption: any, nxtOption: any) => (
    prevOption?.title > nxtOption?.title ? 1 : -1
  )) : arr
);

export const dropdownOptionsforStateCounty = (arr: any) => {
  const stateList: any[] = [];
  arr?.length && arr?.forEach((item: any) => {
    if (item?.studyArea?.counties?.length > 0) {
      item?.studyArea?.counties?.forEach((obj: any) => {
        const index = stateList?.findIndex((state: any) => state?.geoId === obj?.state?.geoId);
        const getState = stateList?.find((state: any) => state?.geoId === obj?.state?.geoId);
        if (getState) {
          stateList?.[index]?.children.push({
            key: `${obj?.state?.geoId}-${obj?.geoId}-${obj?.name}`,
            title: obj?.name,
            geoId: obj?.geoId
          });
        } else {
          stateList.push({
            key: obj?.state?.name,
            title: obj?.state?.name,
            geoId: obj?.state?.geoId,
            children: [
              {
                key: `${obj?.state?.geoId}-${obj?.geoId}-${obj?.name}`,
                title: obj?.name,
                geoId: obj?.geoId
              }
            ]
          });
        }
      });
    }
  });
  const sortedUniqStateList = uniqBySort(stateList);
  const sortedUniqCountyList = sortedUniqStateList?.map((item: any) => ({
    ...item,
    children: uniqBySort(item?.children)?.map((obj: any) => ({ ...obj, key: `${item?.geoId}-${obj?.geoId}-${obj?.title}` }))
  }));
  return sortedUniqCountyList;
};

export const checkIsFeatureEnabled = (features: any, key: string) => features?.find((item: any) => item?.featureKey === key)?.value || false;
