import { getData } from '../apiUtils';
import { client } from '../client';
import { Endpoints } from '../endpoints';

export const getFeatures = async () => client.get(Endpoints.features).then(getData);

export const getFeaturesByClientId = async (clientId: string) => client.get(`${Endpoints.features}/client/${clientId}`).then(getData);

export const updateFeatures = async (id: string, features: any, featureKey: string | null) => (
  client.put(`${Endpoints.features}/${id}`, { features, featureKey }).then(getData)
);

export const createFeatures = async (features: any) => client.post(Endpoints.features, { features }).then(getData);
