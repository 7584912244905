import { styled } from 'styled-components';
import useCustomToken from '../hooks/useCustomToken';
import { TailSpin } from 'react-loader-spinner';

const LoadedContainer = styled('div')`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingScreen = () => {
  const designToken = useCustomToken();

  return (

    <LoadedContainer>
      <TailSpin
        color={designToken?.colorTextTertiary}
      />
    </LoadedContainer>
  );
};

export default LoadingScreen;
