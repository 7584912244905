import { createContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { DynamicThemeType } from '../types/user';
import { locusTokenOverrides } from '../static/common';
import useAuth from '../hooks/useAuth';

interface State {
  dynamicTheme: DynamicThemeType
  setDynamicTheme: ({ colorPrimary, colorBgBase, colorTextBase, colorSuccess, colorWarning, colorError, fontFamily }: DynamicThemeType) => void
}

interface AuthProviderProps {
  children?: ReactNode;
}

const initialState: State = {
  dynamicTheme: {
    colorPrimary: locusTokenOverrides.colorPrimary,
    colorBgBase: locusTokenOverrides.colorBgBase,
    colorTextBase: locusTokenOverrides.colorTextBase,
    colorSuccess: locusTokenOverrides.colorSuccess,
    colorError: locusTokenOverrides.colorError,
    colorWarning: locusTokenOverrides.colorWarning,
    fontFamily: locusTokenOverrides.fontFamily
  },
  setDynamicTheme: () => { }
};

const ThemeContext = createContext<State>({
  ...initialState
});

export const ThemeProvider: React.FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const { userClient } = useAuth();

  const [dynamicTheme, setDynamicTheme] = useState<DynamicThemeType>({
    colorPrimary: userClient?.primaryColor ?? locusTokenOverrides.colorPrimary,
    colorBgBase: locusTokenOverrides.colorBgBase,
    colorTextBase: locusTokenOverrides.colorTextBase,
    colorSuccess: locusTokenOverrides.colorSuccess,
    colorWarning: locusTokenOverrides.colorWarning,
    colorError: locusTokenOverrides.colorError,
    fontFamily: locusTokenOverrides.fontFamily
  });

  useEffect(() => {
    setDynamicTheme({
      colorPrimary: userClient?.primaryColor ?? locusTokenOverrides.colorPrimary,
      colorBgBase: locusTokenOverrides.colorBgBase,
      colorTextBase: locusTokenOverrides.colorTextBase,
      colorSuccess: locusTokenOverrides.colorSuccess,
      colorWarning: locusTokenOverrides.colorWarning,
      colorError: locusTokenOverrides.colorError,
      fontFamily: locusTokenOverrides.fontFamily
    });
  }, [userClient]);

  return (
    <ThemeContext.Provider
      value={{
        dynamicTheme,
        setDynamicTheme
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
