import { Navigate } from 'react-router';
import { Routes } from '../constants';

const ErrorGuard = () => {
  if (localStorage.getItem('access-token')) {
    return <Navigate to={Routes.home} />;
  }

  return <Navigate to="/" />;
};

export default ErrorGuard;
