import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import ErrorGuard from '../guards/ErrorGuard';
import RoleBasedGuard from '../guards/RolebasedGuard';
import { Permissions, Roles, Routes } from '../constants';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const LandingScreen = Loadable(lazy(() => import('../components/LandingScreen')));
const DashboardLayout = Loadable(lazy(() => import('../components/DashboardLayout')));
const Dashboard = Loadable(lazy(() => import('../components/Dashboard')));
const CreateUserForm = Loadable(lazy(() => import('../pages/Create-User')));

const Home = Loadable(lazy(() => import('../pages/Home')));
const StudyArea = Loadable(lazy(() => import('../pages/StudyArea')));
const Projects = Loadable(lazy(() => import('../pages/Projects')));
const PassengerView = Loadable(lazy(() => import('../pages/PassengerView')));
const TransitView = Loadable(lazy(() => import('../pages/TransitView')));
const TruckView = Loadable(lazy(() => import('../pages/TruckView')));
const Collections = Loadable(lazy(() => import('../pages/Collections')));
const CollectionsViewPage = Loadable(lazy(() => import('../pages/CollectionsViewPage')));
const TimePeriod = Loadable(lazy(() => import('../pages/TimePeriod')));
const MapLayers = Loadable(lazy(() => import('../pages/MapLayers')));
const ZoneSystems = Loadable(lazy(() => import('../pages/ZoneSystem')));
const Equity = Loadable(lazy(() => import('../pages/Equity')));
const CreateMapLayer = Loadable(lazy(() => import('../pages/CreateNewMapLayer')));
const UpdateMapLayer = Loadable(lazy(() => import('../pages/UpdateMapLayer')));
const CreateNewEquity = Loadable(lazy(() => import('../pages/CreateNewEquity')));
const CreateNewStudyArea = Loadable(lazy(() => import('../pages/CreateNewStudyArea')));
const CreateNewZoneSystem = Loadable(lazy(() => import('../pages/CreateNewZoneSystem')));
const UserGuides = Loadable(lazy(() => import('../pages/UserGuides')));
const UseCases = Loadable(lazy(() => import('../pages/UseCases')));
const FAQs = Loadable(lazy(() => import('../pages/FAQs')));
const ContactSupport = Loadable(lazy(() => import('../pages/ContactSupport')));
const Theme = Loadable(lazy(() => import('../pages/Theme')));
const Features = Loadable(lazy(() => import('../pages/Features')));
const ProjectCreation = Loadable(lazy(() => import('../pages/ProjectCreation')));
const Clients = Loadable(lazy(() => import('../pages/Clients')));
const ClientCreation = Loadable(lazy(() => import('../pages/ClientCreation')));
const Users = Loadable(lazy(() => import('../pages/Users')));
const Organization = Loadable(lazy(() => import('../pages/Organization')));
const Profile = Loadable(lazy(() => import('../pages/Profile')));
const TruckDashboard = Loadable(lazy(() => import('../pages/TruckDashboard')));
const TransitDashboard = Loadable(lazy(() => import('../pages/TransitDashboard')));
const StatsDashboard = Loadable(lazy(() => import('../pages/StatsDashboard')));
const WhatsNew = Loadable(lazy(() => import('../pages/WhatsNew')));
const WhatsNewCreation = Loadable(lazy(() => import('../components/WhatsNew/WhatsNewCreation')));
const ProjectDetailsView = Loadable(lazy(() => import('../components/Projects/ProjectDetailsView')));
const ClientUpdation = Loadable(lazy(() => import('../components/Clients/ClientUpdation')));

const Guide = Loadable(lazy(() => import('../pages/Guide')));
const SignUp = Loadable(lazy(() => import('../pages/SignUp')));
const RequestPage = Loadable(lazy(() => import('../pages/RequestPage')));
const DataRequestPage = Loadable(lazy(() => import('../components/RequestPage/MapDataRequest')));

const routes: any[] = [
  {
    path: Routes.truck,
    element: (
      <TruckDashboard />
    )
  },
  {
    path: Routes.transit,
    element: (
      <TransitDashboard />
    )
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <LandingScreen />
      </AuthGuard>
    )
  },
  {
    path: '/:slug/signup',
    element: (
      <AuthGuard>
        <SignUp />
      </AuthGuard>
    )
  },
  {
    path: `${Routes.person}/:viewId`,
    element: (
      <GuestGuard>
        <PassengerView />
      </GuestGuard>
    ),
  },
  {
    path: `${Routes.personPlus}/:viewId`,
    element: (
      <GuestGuard>
        <PassengerView />
      </GuestGuard>
    ),
  },
  {
    path: `${Routes.truck}/:viewId`,
    element: (
      <GuestGuard>
        <TruckView />
      </GuestGuard>
    ),
  },
  {
    path: `${Routes.transit}/:viewId`,
    element: (
      <GuestGuard>
        <TransitView />
      </GuestGuard>
    ),
  },
  {
    path: '/',
    element: (
      <GuestGuard>
        <DashboardLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: Routes.dashboard,
        element: <Dashboard />
      },
      {
        path: Routes.clients,
        element: (
          <RoleBasedGuard
            RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}
            RoleAccess={[Roles.SITE_ADMIN, Roles.SITE_ADMIN_R]}
          >
            <Clients />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.clientCreation,
        element: (
          <RoleBasedGuard
            RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}
            RoleAccess={[Roles.SITE_ADMIN]}
          >
            <ClientCreation />
          </RoleBasedGuard>
        )
      },
      {
        path: `${Routes.clients}/:id`,
        element: (
          <RoleBasedGuard
            RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}
            RoleAccess={[Roles.SITE_ADMIN, Roles.SITE_ADMIN_R]}
          >
            <ClientUpdation />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.home,
        element: (
          <RoleBasedGuard>
            <Home />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.studyArea,
        element: (
          <RoleBasedGuard>
            <StudyArea />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.studyAreaCreation,
        element: (
          <RoleBasedGuard
            clientAccess
            RoleAccess={[Roles.SITE_ADMIN, Roles.CLIENT_ADMIN, Roles.USER]}
          >
            <CreateNewStudyArea />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.projects,
        element: (
          <RoleBasedGuard>
            <Projects />
          </RoleBasedGuard>
        )
      },
      {
        path: `${Routes.projects}/:id/edit`,
        element: (
          <RoleBasedGuard>
            <Projects />
          </RoleBasedGuard>
        )
      },
      {
        path: `${Routes.projects}/:id`,
        element: (
          <RoleBasedGuard>
            <ProjectDetailsView />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.collections,
        element: (
          <RoleBasedGuard>
            <Collections />
          </RoleBasedGuard>
        )
      },
      {
        path: `${Routes.collections}/:id`,
        element: (
          <RoleBasedGuard>
            <CollectionsViewPage />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.timeofDay,
        element: (
          <RoleBasedGuard>
            <TimePeriod />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.mapLayers,
        element: (
          <RoleBasedGuard>
            <MapLayers />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.mapLayerCreation,
        element: (
          <RoleBasedGuard
            RoleAccess={[Roles.SITE_ADMIN, Roles.CLIENT_ADMIN, Roles.USER]}
          >
            <CreateMapLayer />
          </RoleBasedGuard>
        )
      },
      {
        path: `${Routes.mapLayers}/:id`,
        element: (
          <RoleBasedGuard
            RoleAccess={[Roles.SITE_ADMIN, Roles.CLIENT_ADMIN, Roles.USER]}
          >
            <UpdateMapLayer />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.zoneSystem,
        element: (
          <RoleBasedGuard>
            <ZoneSystems />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.zoneSystemCreation,
        element: (
          <RoleBasedGuard
            RoleAccess={[Roles.SITE_ADMIN, Roles.CLIENT_ADMIN, Roles.USER]}
          >
            <CreateNewZoneSystem />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.equityCreation,
        element: (
          <RoleBasedGuard
            RoleAccess={[Roles.SITE_ADMIN, Roles.CLIENT_ADMIN, Roles.USER]}
          >
            <CreateNewEquity />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.userGuides,
        element: (
          <RoleBasedGuard>
            <UserGuides />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.equity,
        element: (
          <RoleBasedGuard>
            <Equity />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.useCases,
        element: (
          <RoleBasedGuard>
            <UseCases />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.faqs,
        element: (
          <RoleBasedGuard>
            <FAQs />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.contactSupport,
        element: (
          <RoleBasedGuard>
            <ContactSupport />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.theme,
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}>
            <Theme />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.features,
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}>
            <Features />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.projectCreation,
        element: (
          <RoleBasedGuard
            clientAccess
            RoleAccess={[Roles.SITE_ADMIN, Roles.CLIENT_ADMIN, Roles.USER]}
          >
            <ProjectCreation />
          </RoleBasedGuard>
        )
      },
      {
        path: `${Routes.addDashboard}/:id`,
        element: (
          <RoleBasedGuard>
            <ProjectCreation />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.users,
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_USER]}>
            <Users />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.organization,
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_USER]}>
            <Organization />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.stats,
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_USER]}>
            <StatsDashboard />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.profile,
        element: (
          <RoleBasedGuard>
            <Profile />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.guide,
        element: (
          <RoleBasedGuard>
            <Guide />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.whatsNew,
        element: (
          <RoleBasedGuard>
            <WhatsNew />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.whatsNewRelease,
        element: (
          <RoleBasedGuard
            RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}
            RoleAccess={[Roles.SITE_ADMIN]}
          >
            <WhatsNewCreation />
          </RoleBasedGuard>
        )
      },
      {
        path: `${Routes.whatsNewRelease}/:id`,
        element: (
          <RoleBasedGuard
            RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}
            RoleAccess={[Roles.SITE_ADMIN]}
          >
            <WhatsNewCreation />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.dataRequest,
        element: (
          <RoleBasedGuard>
            <DataRequestPage />
          </RoleBasedGuard>
        )
      },
      {
        path: Routes.signUpRequests,
        element: (
          <RoleBasedGuard>
            <RequestPage />
          </RoleBasedGuard>
        )
      }
    ]
  },
  {
    path: '*',
    element: (
      <ErrorGuard />
    )
  }
];

export default routes;
