import { createContext, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import KeycloakServices from '../Keycloak-config';

import { authenticateUser } from '../api/requests/authenticateUser';
import { getAllClients } from '../api/requests/clients';
import { getClientUser, updateUserActivityForLogin } from '../api/requests/user';
import { getAllAccessRoles } from '../api/requests/accessRole';

import { Role, User } from '../types/user';
import { Roles, Routes, StorageKeys, Tokens, UserActivityTypes } from '../constants';
import { getDarkColor } from '../utils/utils';
import { getFeatures, getFeaturesByClientId } from '../api/requests/features';
import { debounce } from 'lodash';

interface State {
  user: User | null;
  permissions: string[];
  isLoading: boolean;
  isAuthenticated: boolean;
  clientUsers: User[];
  roles: Role[];
  clientList: any[];
  userRole: string;
  userClient: any;
  clientId: string | null;
  changeClientId: (val: string | null, drop?: boolean, userData?: any) => void;
  isDropdownClicked: boolean;
  globalFeatures: any[];
  productsAccess: string[];
  isReadOnly: boolean
}

interface AuthProviderProps {
  children?: ReactNode;
}

const initialState: State = {
  user: null,
  permissions: [],
  clientUsers: [],
  roles: [],
  isLoading: true,
  isAuthenticated: false,
  clientList: [],
  userRole: '',
  userClient: null,
  clientId: null,
  changeClientId: (val: string | null, drop?: boolean, userData?: any) => { },
  isDropdownClicked: false,
  globalFeatures: [],
  productsAccess: [],
  isReadOnly: false
};

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    user: User | null;
    permissions: string[];
    isLoading: boolean;
    isAuthenticated: boolean;
    clientUsers: User[];
    roles: Role[];
    clientList: any[];
    userRole: string;
    userClient: any;
    clientId: string | null;
    changeClientId: (val: string | null, drop?: boolean, userData?: any) => void;
    isDropdownClicked: boolean;
    globalFeatures: any[];
    productsAccess: string[];
    isReadOnly: boolean;
  };
};

type Action = InitializeAction;

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const {
      user,
      permissions,
      isLoading,
      isAuthenticated,
      clientUsers,
      roles,
      clientList,
      userRole,
      userClient,
      clientId,
      changeClientId,
      isDropdownClicked,
      globalFeatures,
      productsAccess,
      isReadOnly
    } = action.payload;

    return {
      ...state,
      isAuthenticated,
      user,
      permissions,
      isLoading,
      clientUsers,
      roles,
      clientList,
      userRole,
      userClient,
      clientId,
      changeClientId,
      isDropdownClicked,
      globalFeatures,
      productsAccess,
      isReadOnly
    };
  },
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<State>({
  ...initialState
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const localClientId = localStorage.getItem('x-lcs-clientid');
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const dashboardUrl = [Routes.person, Routes.truck, Routes.transit, Routes.personPlus];

  const isDashboardUrl = dashboardUrl?.find((item: any) => location?.pathname?.includes(item));

  const initialize = debounce(async (clientId: string | null, isDropdownClicked?: boolean, userInfo?: any): Promise<void> => {
    try {
      let isReadOnly: boolean = false;
      let selectedClientId = clientId;
      let userSingleClientId = '';
      const getLocalClientId = localStorage.getItem('x-lcs-clientid');
      let user: any = userInfo;
      if (!userInfo) {
        // For fetching user details
        const userDetails = localStorage.getItem(Tokens.accessToken) && await authenticateUser();
        user = userDetails?.data?.user;
      }
      userSingleClientId = user?.client?.[0]?.id;
      let permissions: any = [];
      let clientUserDetails: any;
      let userRole: string = '';
      let userClient: any;
      let clientList = [];
      let productsAccess: string[] = [];
      const featuresList: any[] = [];
      if (user?.client?.length === 1) {
        selectedClientId = userSingleClientId;
      }
      // For checking the user is Site Admin
      const isSiteAdmin = user?.role?.find((role: any) => [Roles.SITE_ADMIN, Roles.SITE_ADMIN_R]?.includes(role?.name));
      if (!localStorage.getItem(StorageKeys.loggedIn) && user && (selectedClientId || isSiteAdmin)) {
        // For updating user activity
        const updateUserDetails = await updateUserActivityForLogin(UserActivityTypes.Login, selectedClientId ?? '');
        localStorage.setItem(StorageKeys.loggedIn, 'true');
      }
      if (isSiteAdmin) {
        // For setting user role if site admin
        userRole = isSiteAdmin?.name;
        if (selectedClientId) {
          // For fetching client list if site admin
          // For fetching features for a clients
          const [clientDetails, features] = await Promise.all([
            !isDashboardUrl && await getAllClients(),
            await getFeaturesByClientId(selectedClientId ?? userSingleClientId ?? '')
          ]);
          featuresList.push(...features?.data);
          clientList = clientDetails?.data;
          // For setting user client by selected client id if site admin
          userClient = clientDetails?.data?.find((client: any) => client?.id === selectedClientId);
          productsAccess = userClient?.clientproducts?.map((item: any) => item?.product?.name);
        } else {
          // For fetching client list if site admin
          // For fetching all features
          const [clientDetails, features] = await Promise.all([
            !isDashboardUrl && await getAllClients(),
            !isDashboardUrl && await getFeatures()
          ]);
          features?.data?.forEach((feature: any) => {
            const featuresDetails = feature?.features?.map((item: any) => ({
              ...item,
              id: feature?.id
            }));
            featuresList.push(...featuresDetails);
          });
          clientList = clientDetails?.data;
          // For setting user client by selected client id if site admin
          userClient = null;
        }
      } else {
        // For other roles except site admin
        userClient = user?.client?.find((client: any) => (
          selectedClientId ? (client?.id === selectedClientId) : (client?.id === userSingleClientId)));
        userRole = userClient?.role?.[0]?.name;
        permissions = userClient?.role?.[0]?.permissions;
        productsAccess = userClient?.clientproducts?.map((item: any) => item?.product?.name);
        // For fetching users of a particular client if not site admin
        // For fetching features for a clients
        const [features, userClientDetails]: any = localStorage.getItem(Tokens.accessToken) && await Promise.all([
          !isDashboardUrl && getFeaturesByClientId(selectedClientId ?? userSingleClientId ?? ''),
          getClientUser(selectedClientId ?? userSingleClientId)
        ]);
        features?.data?.length && featuresList.push(...features?.data);
        clientUserDetails = userClientDetails;
      }
      const clientUsers: any = clientUserDetails?.data?.rows?.length > 0 ? (
        clientUserDetails?.data?.rows?.map((item: any) => ({
          ...item,
          backgroundColor: getDarkColor()
        }))
      ) : [];
      // For fetching access roles
      const rolesFetched = await getAllAccessRoles();
      const roles = rolesFetched?.data?.filter((item: any) => item.name !== 'Owner') ?? [];
      // For setting global client id
      if ((selectedClientId === 'all') || !selectedClientId) {
        localStorage.removeItem('x-lcs-clientid');
      } else {
        localStorage.setItem('x-lcs-clientid', selectedClientId ?? '');
      }

      if (getLocalClientId !== clientId) {
        const firstSegment = `/${window.location.pathname.split('/').filter(Boolean)[0]}`;
        if (window.location.pathname !== firstSegment) {
          navigate(firstSegment);
        }
      }
      if (userRole === Roles.SITE_ADMIN_R) {
        isReadOnly = true;
      }
      dispatch({
        type: 'INITIALIZE',
        payload: {
          user,
          permissions,
          isLoading: false,
          isAuthenticated: true,
          clientUsers,
          roles,
          clientList,
          userRole,
          userClient,
          clientId: selectedClientId,
          changeClientId: initialize,
          isDropdownClicked: isDropdownClicked ?? false,
          globalFeatures: featuresList,
          productsAccess,
          isReadOnly
        }
      });
    } catch (err: any) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          user: null,
          permissions: [],
          isAuthenticated: false,
          isLoading: false,
          clientUsers: [],
          roles: [],
          clientList: [],
          userRole: '',
          userClient: null,
          clientId: '',
          changeClientId: (val: string | null, drop?: boolean, userData?: any) => { },
          isDropdownClicked: false,
          globalFeatures: [],
          productsAccess: [],
          isReadOnly: false
        }
      });
    }
  }, 500);

  useEffect(() => {
    localStorage.removeItem(StorageKeys.redirect);
    KeycloakServices.initKeycloak(() => initialize(localClientId));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
