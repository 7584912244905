import { theme } from 'antd';
import { locusTokenOverrides } from '../static/common';

const { useToken } = theme;

const useCustomToken = () => {
  const { token } = useToken();

  return {
    ...token,
    whiteBgColor: locusTokenOverrides.whiteColor,
    pendingColor: locusTokenOverrides.pendingColor,
    pendingColorHover: locusTokenOverrides.pendingColorHover
  };
};

export default useCustomToken;
